<template>
	<v-app>
		<template v-if="isVerified && uuid">
			<QuotationApprove :uuid="uuid" :details="quotationData" />
		</template>
		<template>
			<Dialog :dialog="verificationDialog" :dialog-width="800">
				<template v-slot:title> Verification </template>
				<template v-slot:body>
					<v-form
						ref="verificationForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="verifyUser"
					>
						<div class="row">
							<div class="col-md-12">
								<TextInput
									hide-details
									:id="`verificationCode`"
									placeholder="Verification Code"
									v-model.trim="verificationCode"
									:rules="[
										vrules.required(verificationCode, 'Security Code'),
										vrules.minLength(verificationCode, 'Security Code', 4),
										vrules.maxLength(verificationCode, 'Security Code', 10),
									]"
									:class="{
										required: !verificationCode,
									}"
									:disabled="pageLoading"
									:loading="pageLoading"
								></TextInput>
							</div>
						</div>
					</v-form>
				</template>
				<template v-slot:action>
					<v-btn
						:disabled="pageLoading"
						:loading="pageLoading"
						class="mx-2 custom-grey-border custom-bold-button white--text"
						color="cyan"
						v-on:click="verifyUser"
					>
						Submit
					</v-btn>
				</template>
			</Dialog>
		</template>
	</v-app>
</template>

<script>
import QuotationApprove from "@/view/module/approve/QuotationApprove";
import { SET_ERROR } from "@/core/services/store/common.module";
import { VerifyUrl, getQuotationForApproval } from "@/core/lib/setting.lib";

import TextInput from "@/view/components/TextInput";
import Dialog from "@/view/components/Dialog";
import { mapGetters } from "vuex";
export default {
	name: "quotation-approval-mail",
	data() {
		return {
			uuid: null,
			token: null,
			verificationDialog: true,
			quotationData: new Object(),
			isVerified: false,
			pageLoading: false,
			formValid: true,
			verificationCode: null,
		};
	},
	components: {
		QuotationApprove,
		TextInput,
		Dialog,
	},
	created() {
		const { token } = this.$route.query;
		this.token = token;
	},
	methods: {
		getQuotation() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.verificationForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				//return false;
			}

			if (!_this.$refs.verificationForm.validate()) {
				//return false;
			}

			_this.isVerified = false;

			_this.validationErrorBag = new Array();

			getQuotationForApproval({ quotation: this.uuid })
				.then((data) => {
					this.quotationData = data;
					this.uuid = data.uuid;
					this.isVerified = true;
					this.verificationDialog = false;
				})
				.catch((error) => {
					if (error && error.data) {
						alert(error.data.message);
						console.log(error.data.message);
						this.$store.commit(SET_ERROR, [
							{ model: true, message: "Please Select Atleast One Permission." },
						]);
					}
				});
		},
		verifyUser() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.verificationForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.verificationForm.validate()) {
				return false;
			}

			_this.isVerified = false;

			_this.validationErrorBag = new Array();

			VerifyUrl(this.token, { code: this.verificationCode, type: 3 })
				.then((data) => {
					this.quotationData = data;
					this.uuid = data.uuid;
					this.isVerified = true;
					this.verificationDialog = false;
				})
				.catch((error) => {
					if (error && error.data) {
						alert(error.data.message);
						console.log(error.data.message);
						this.$store.commit(SET_ERROR, [
							{ model: true, message: "Please Select Atleast One Permission." },
						]);
					}
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
		stringId() {
			return this.stringUnique();
		},
	},

	mounted() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}

		this.getQuotation();
	},
};
</script>
